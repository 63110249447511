import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const App = () => {
    return (
        <Router>
            <Header />
            <Home /> {/* Ahora renderizas Home directamente */}
            <Footer />
        </Router>
    );
};

export default App;
