import React from "react";
import Slider from "react-slick";
import "./Carousel.css";

const CustomCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };

  const images = [
    {
      large: "/images/darksouls3.jpg",
      smallTop: "/images/vrising.jpg",
      smallBottom: "/images/wukong.jpg",
    },
    {
      large: "/images/ragnarok.jpeg",
      smallTop: "/images/silksong.jpg",
      smallBottom: "/images/zelda.jpg",
    },
    {
      large: "/images/left4dead2.jpg",
      smallTop: "/images/devour.jpg",
      smallBottom: "/images/elden.jpeg",
    },
  ];

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="carousel-slide">
            <div className="image-container">
              <div className="large-image">
                <img src={image.large} alt={`Imagen Grande ${index}`} />
              </div>
              <div className="small-images">
                <div className="small-image-top">
                  <img src={image.smallTop} alt={`Imagen Pequeña 1 ${index}`} />
                </div>
                <div className="small-image-bottom">
                  <img src={image.smallBottom} alt={`Imagen Pequeña 2 ${index}`} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        right: "-30px", 
        zIndex: 1,
        top: "50%",
        transform: "translateY(-50%)",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        left: "-30px", 
        zIndex: 1,
        top: "50%",
        transform: "translateY(-50%)",
      }}
      onClick={onClick}
    />
  );
}

export default CustomCarousel;
