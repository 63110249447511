import React, { useEffect, useState } from 'react';
import Carousel from '../components/Carousel';
import GameModal from '../components/GameModal'; 
import './Home.css';

const Home = () => {
    const [games, setGames] = useState([]);
    const [selectedGame, setSelectedGame] = useState(null); 
    const [section] = useState('popular'); 

    useEffect(() => {
        const fetchGames = async () => {
            let categoryQuery = '';
            if (section === 'consolas') {
                categoryQuery = 'platforms=xbox,nintendo,playstation';
            } else if (section === 'pc') {
                categoryQuery = 'platforms=steam';
            } else if (section === 'movil') {
                categoryQuery = 'platforms=steam';
            } 

            try {
                const response = await fetch(`https://api.rawg.io/api/games?key=496261cea758462cb6b64db2ab9cb30f&ordering=-rating&page_size=12&${categoryQuery}`);
                const data = await response.json();
                setGames(data.results);
            } catch (error) {
                console.error("Error fetching games:", error);
            }
        };

        fetchGames();
    }, [section]);

    const handleCardClick = (game) => {
        setSelectedGame(game); // Setear el juego seleccionado
    };

    const closeModal = () => {
        setSelectedGame(null); // Cerrar el modal
    };

    return (
        <div className="home-container">
            <Carousel />

            <h2 className="popular-title">Más populares</h2>
            <div className="cards-container">
                {/** Columna 1 */}
                <div className="column">
                    {games.slice(0, 6).map((game) => (
                        <div className="card" key={game.id} onClick={() => handleCardClick(game)}>
                            <div className="card-content">
                                <img
                                    className="card-image"
                                    src={game.background_image || '/covers/default_cover.jpg'}
                                    alt={game.name}
                                />
                                <div>
                                    <div className="game-title">{game.name}</div>
                                    <div className="icons">
                                        {game.platforms.map((platform) => {
                                            if (platform.platform.name === 'PC') {
                                                return <img key="steam" src="/covers/steam.png" alt="Steam" />;
                                            } else if (platform.platform.name === 'Xbox One') {
                                                return <img key="xbox" src="/covers/xbox.png" alt="Xbox" />;
                                            } else if (platform.platform.name === 'PlayStation 4') {
                                                return <img key="play" src="/covers/play.png" alt="PlayStation" />;
                                            } else if (platform.platform.name === 'Nintendo Switch') {
                                                return <img key="switch" src="/covers/switch.png" alt="Switch" />;
                                            }
                                            return null;
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="game-score">{game.rating}</div>
                        </div>
                    ))}
                </div>

                {/** Columna 2 */}
                <div className="column">
                    {games.slice(6, 12).map((game) => (
                        <div className="card" key={game.id} onClick={() => handleCardClick(game)}>
                            <div className="card-content">
                                <img
                                    className="card-image"
                                    src={game.background_image || '/covers/default_cover.jpg'}
                                    alt={game.name}
                                />
                                <div>
                                    <div className="game-title">{game.name}</div>
                                    <div className="icons">
                                        {game.platforms.map((platform) => {
                                            if (platform.platform.name === 'PC') {
                                                return <img key="steam" src="/covers/steam.png" alt="Steam" />;
                                            } else if (platform.platform.name === 'Xbox One') {
                                                return <img key="xbox" src="/covers/xbox.png" alt="Xbox" />;
                                            } else if (platform.platform.name === 'PlayStation 4') {
                                                return <img key="play" src="/covers/play.png" alt="PlayStation" />;
                                            } else if (platform.platform.name === 'Nintendo Switch') {
                                                return <img key="switch" src="/covers/switch.png" alt="Switch" />;
                                            }
                                            return null;
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="game-score">{game.rating}</div>
                        </div>
                    ))}
                </div>
            </div>

            {/** Banner */}
            <div className="banner-container">
                <img
                    className="banner-image"
                    src="/images/zeldabreathbanner.png"
                    alt="Banner"
                />
            </div>

            {/** Modal para el juego seleccionado */}
            <GameModal game={selectedGame} onClose={closeModal} />
        </div>
    );
};

export default Home;
