import React from 'react';

// Función para obtener el nombre de la plataforma
const getPlatformName = (slug) => {
    if (slug.includes('xbox')) {
        return 'Xbox'; // Nombre genérico para Xbox
    } else if (slug.includes('playstation')) {
        return 'PlayStation'; // Nombre genérico para PlayStation
    } else if (slug.includes('nintendo')) {
        return 'Nintendo'; // Nombre genérico para Nintendo
    } else if (slug === 'pc') {
        return 'PC'; // Solo PC
    }
    return 'Unknown'; // Nombre por defecto si no se encuentra
};

const Modal = ({ game, onClose }) => {
    if (!game) return null; // No mostrar nada si no hay información del juego

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <span className="close-modal" onClick={onClose}>×</span>
                <h2>{game.name}</h2>
                <img src={game.background_image} alt={game.name} style={{ width: '100%' }} />
                <div className="platforms">
                    {game.platforms.map((platform) => (
                        <span key={platform.platform.id} style={{ marginRight: '10px' }}>
                            {getPlatformName(platform.platform.slug)} {/* Muestra solo el nombre de la plataforma */}
                        </span>
                    ))}
                </div>
                <p>Rating: {game.rating}</p>
            </div>
        </div>
    );
};

export default Modal;
