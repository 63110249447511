import React, { useState, useEffect } from 'react';
import './Header.css';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import GameModal from './GameModal'; // Asegúrate de importar el componente GameModal

// Configura Firebase
const firebaseConfig = {
    apiKey: "AIzaSyCd18cIUXMOfLndcG3uZACKhx8dsgJ0r8",
    authDomain: "technoblog-2ad7f.firebaseapp.com",
    projectId: "technoblog-2ad7f",
    storageBucket: "technoblog-2ad7f.appspot.com",
    messagingSenderId: "106722754057",
    appId: "1:106722754057:web:df3551f5a9582ded3b02d8"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Header = () => {
    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedGame, setSelectedGame] = useState(null);
    const [isGameDetailModalOpen, setIsGameDetailModalOpen] = useState(false);

    const openSubscriptionModal = () => setIsSubscriptionModalOpen(true);
    const closeSubscriptionModal = () => {
        setIsSubscriptionModalOpen(false);
        setName('');
        setLastName('');
        setEmail('');
        setError('');
    };

    const checkEmailExists = async (email) => {
        const q = query(collection(db, 'suscriptores'), where('email', '==', email));
        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty; // Devuelve true si el correo existe
    };

    const handleSubscribe = async (e) => {
        e.preventDefault();
        setError(''); // Limpiar errores previos

        try {
            // Verifica si el correo ya existe
            const emailExists = await checkEmailExists(email);
            if (emailExists) {
                setError('El correo electrónico ya está suscrito.'); // Mensaje de error si el correo ya existe
                return;
            }

            // Agregar el nuevo suscriptor
            await addDoc(collection(db, 'suscriptores'), {
                name: name,
                lastName: lastName,
                email: email
            });
            alert('¡Suscripción exitosa!');
            closeSubscriptionModal();
        } catch (error) {
            console.error("Error al suscribirse: ", error);
            setError('Hubo un error al suscribirse. Intenta de nuevo.'); // Mostrar mensaje de error
        }
    };

    // Función para manejar el cambio en la barra de búsqueda
    const handleSearchChange = async (e) => {
        const value = e.target.value;
        setSearchTerm(value);

        // Aquí puedes realizar la llamada a la API para buscar juegos
        if (value) {
            try {
                const response = await fetch(`https://api.rawg.io/api/games?key=496261cea758462cb6b64db2ab9cb30f&search=${value}`);
                const data = await response.json();
                setSearchResults(data.results || []); // Actualiza los resultados de búsqueda
            } catch (error) {
                console.error('Error al buscar juegos:', error);
            }
        } else {
            setSearchResults([]); // Limpia los resultados si no hay búsqueda
        }
    };

    // Función para manejar el clic en un resultado de búsqueda
    const handleResultClick = (game) => {
        setSelectedGame(game);
        setIsGameDetailModalOpen(true); // Abre el modal de detalles del juego
    };

    // Maneja el clic fuera del menú de resultados
    const handleClickOutside = (event) => {
        const searchContainer = document.querySelector('.search-container-header');
        if (searchContainer && !searchContainer.contains(event.target)) {
            setSearchResults([]); // Oculta el menú de resultados
        }
    };

    // Usa useEffect para agregar y limpiar el evento de clic
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <header className="header">
            <div className="center-section">
                <img src="/images/logotechno.jpg.png" alt="Logo TechnoBlog" className="logo-image" />
                <h1 className="logo">TechnoBlog</h1>
                <div className="search-container-header">
                    <input 
                        type="text" 
                        placeholder="Buscar..." 
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    {searchResults.length > 0 && (
                        <div className="search-results">
                            {searchResults.map(game => (
                                <div 
                                    key={game.id} 
                                    className="search-result" 
                                    onClick={() => handleResultClick(game)}
                                >
                                    {game.name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="subscribe-container">
                    <button className="subscribe-button" onClick={openSubscriptionModal}>Suscribirse</button>
                </div>
            </div>

            {/* Modal de Suscripción */}
            {isSubscriptionModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close-modal" onClick={closeSubscriptionModal}>×</span>
                        <h2>Suscripción</h2>
                        {error && <p className="error-message">{error}</p>} {/* Mostrar mensaje de error */}
                        <form onSubmit={handleSubscribe}>
                            <input
                                type="text"
                                placeholder="Nombre"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Apellidos"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                            />
                            <input
                                type="email"
                                placeholder="Correo Electrónico"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <button type="submit">Enviar</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Modal de Detalle del Juego */}
            {isGameDetailModalOpen && selectedGame && (
                <GameModal 
                    game={selectedGame} 
                    onClose={() => setIsGameDetailModalOpen(false)} 
                />
            )}
        </header>
    );
};

export default Header;
